import axios from "axios";

export default {
  async getMenuForServiceAndTable(service, tableId, isPda, pdaId) {
    let endpoint = `/menu/departments/${service}?tableId=${tableId}`;
    if (isPda) {
      endpoint = endpoint + "&isPda=true";
    }
    if (pdaId) {
      endpoint = endpoint + "&pdaId=" + pdaId;
    }
    let result = await axios.get(endpoint);
    return result.data;
  },

  async getFilteredMenu(restaurantId, ids) {
    let idsAsString = ids.join();
    let result = await axios.get(`/menu/filtered?categoryIds=${idsAsString}&restaurantId=${restaurantId}`)

    return result.data;
  },

  async getServices(tableId) {
    return axios
      .get(`/menu/departments?tableId=${tableId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error.response));
  },

  async increaseMenuScans(serviceId) {
    axios.post(`/restaurant/increase-scan-count?restaurantId=${serviceId}`)
      .catch((error) => console.log(error));
  }
};
